@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;0,800;0,900;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    color: var(--text-color);
    font-size: 14px;
}

* {
    font-family: "Poppins", sans-serif;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 20px;
}

a {
    color: #0D8081;
    text-decoration: none;
    font-weight: 400;
}

hr {
    border-top: 1px solid #ccc;
}

img {
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
}

input:disabled {
    background-color: #fff;
}

// Form
.required {
    color: #FF5141;
}

.form-submitted .p-inputtext.ng-invalid {
    border-color: #FF5141;
}

.form-submitted .ng-invalid .p-calendar .p-inputtext {
    border-color: #FF5141;
}

// Chips
.p-chip.danger-chip {
    background: #FF5141;
    color: var(--surface-a);
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 0 0.5rem;

    .p-chip-text {
        font-size: 12px;
        font-weight: 400;
    }
}

// Input
.p-fileupload .p-button-icon {
    display: none;
}

input[type="text"]:read-only {
    outline: none !important;
}

// Custom Buttons
.p-button.p-button-orange, .p-buttonset.p-button-orange > .p-button, .p-splitbutton.p-button-orange > .p-button {
    color: #ffffff;
    background: #FF7100;
    border: 1px solid #FF7100;
}

.p-button.p-button-orange:enabled:hover, .p-buttonset.p-button-orange > .p-button:enabled:hover, .p-splitbutton.p-button-orange > .p-button:enabled:hover {
    background: #ff821c;
    border-color: #ff821c;
}

.p-button.p-button-violet.p-button-outlined, .p-buttonset.p-button-violet > .p-button.p-button-outlined, .p-splitbutton.p-button-violet > .p-button.p-button-outlined {
    background-color: transparent;
    color: var(--secondary-color);
    border: 2px solid;
}

.p-button.p-button-violet.p-button-outlined:enabled:hover, .p-buttonset.p-button-violet > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-violet > .p-button.p-button-outlined:enabled:hover {
    background: rgba(98, 44, 136, 0.04);
    color: var(--secondary-color);
    border: 2px solid;
}

.p-button.p-button-violet, .p-buttonset.p-button-violet > .p-button, .p-splitbutton.p-button-violet > .p-button {
    color: #ffffff;
    background: var(--secondary-color);
    border: 2px solid var(--secondary-color);
}

.p-button.p-button-violet:enabled:hover, .p-buttonset.p-button-violet > .p-button:enabled:hover, .p-splitbutton.p-button-violet > .p-button:enabled:hover {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
}

.p-button.p-button-blue.p-button-text, .p-buttonset.p-button-blue > .p-button.p-button-text, .p-splitbutton.p-button-blue > .p-button.p-button-text {
    background-color: transparent;
    color: #0D8081;
    border-color: transparent;
}

.p-button.p-button-blue.p-button-text:enabled:hover, .p-buttonset.p-button-blue > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-blue > .p-button.p-button-text:enabled:hover {
    background: rgba(96, 125, 139, 0.04);
    border-color: transparent;
    color: #0D8081;
}

.p-button.p-button-blue.p-button-text:enabled:active, .p-buttonset.p-button-blue > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-blue > .p-button.p-button-text:enabled:active {
    background: rgba(96, 125, 139, 0.16);
    border-color: transparent;
    color: #607D8B;
}

// Toast
.p-toast.custom-toast {
    width: auto;
    max-width: 25rem;

    .p-toast-message .p-toast-message-content .p-toast-message-icon {
        display: none;
    }

    .p-toast-message .p-toast-message-content .p-toast-message-text {
        margin-right: 1rem;
    }
}

.p-toast-message-content .p-toast-message-icon {
    display: none;
}

// Other
.profile-image {
    border-radius: 50%;
    border: 1px solid #ccc;
    margin-right: 10px;
}

// Quill
.ql-toolbar.ql-snow {
    background-color: #ffffff;
    border-radius: 5px 5px 0 0;
    border: none !important;
}

.ql-container.ql-snow {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0 0 5px 5px;
    border: none !important;
}
